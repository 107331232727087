@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: #c1c1c1; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .btn-primary {
    @apply bg-app-primary  py-2 px-5  text-white  rounded-xl font-bold cursor-pointer w-full disabled:opacity-80 disabled:cursor-not-allowed;
  }

  .btn-secondary {
    @apply bg-app-secondary  py-2 px-5  text-black  rounded-xl font-bold cursor-pointer w-full disabled:opacity-80 disabled:cursor-not-allowed;
  }
  .btn-danger {
    @apply bg-red-500 py-2 px-5 text-white rounded font-bold;
  }
  .btn-success {
    @apply bg-green-500 py-2 px-5 text-white rounded font-bold;
  }

  .btn-danger {
    @apply bg-red-500 py-2 px-5 text-white rounded font-bold;
  }

  .btn-primary-rounded {
    @apply bg-primary rounded-full p-1 text-white;
  }
}

@layer components {
  .tabs {
    @apply w-full  z-50 font-bold py-5 text-center border-b-4;
  }
  .tabs-active {
    @apply border-secondary text-secondary;
  }
}
